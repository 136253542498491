import { PaletteVariant } from 'shared/types/common'

export const getVariantByOperationName = (name: string): PaletteVariant => {
  switch (name.toLowerCase()) {
    case 'разрешено':
    case 'продажи':
      return 'success'
    case 'не подтверждено':
    case 'запрещено':
    case 'закупки':
      return 'error'
    case 'бухгалтерия':
      return 'secondary'
    case 'маркетинг':
      return 'warning'
    case 'логистика':
    case 'выдано':
      return 'primary'
    default:
      return 'info'
  }
}

import { ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { setupListeners } from '@reduxjs/toolkit/query'
import { Outlet } from 'react-router'
import { GlobalStyle } from './styles/GlobalStyle'
import { theme } from './theme/theme'
// import '../firebase'
// import { onMessageListener, requestForToken } from '../firebase'
import { store } from './store/store'
import { OfflineNotify } from 'shared/ui/OfflineNorify/OfflineNorify'

function App() {
  // const [show, setShow] = useState(false)
  // const [notification, setNotification] = useState({ title: '', body: '' })
  setupListeners(store.dispatch)

  // const isMatchLogin = useMatch(RoutePath.login)
  // const isMatchVerify = useMatch(RoutePath.verify)

  // userModel.useUserQuery(undefined, { skip: !!isMatchLogin || !!isMatchVerify })

  // requestForToken()

  // onMessageListener()
  //   .then((payload) => {
  //     setShow(true)
  //     if (payload) {
  //       setNotification({
  //         title: payload?.notification?.title ?? '',
  //         body: payload?.notification?.body ?? '',
  //       })
  //     }
  //     console.log(payload)
  //   })
  //   .catch((err) => console.log('failed: ', err))

  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <OfflineNotify />
          <Outlet />
          {/* <ConnectionStatusProvider>dfg</ConnectionStatusProvider> */}
        </LocalizationProvider>
      </ThemeProvider>
    </>
  )
}

export default App

import { useMemo, useRef } from 'react'
import { Stack, Box, Typography, Switch, Skeleton } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import Grid from '@mui/material/Grid2'
import CircularProgress from '@mui/material/CircularProgress'
import moment, { Moment } from 'moment'
import { paymentModel } from 'entities/payment'
import { Calendar } from 'widgets/Calendar'
import { ToggleOperationFilter } from 'shared/ui/ToggleOperationFilter/ToggleOperationFilter'
import { getVariantByOperationName } from 'shared/lib/getOperationVariantByName'
import { grey } from '@mui/material/colors'
import { useTheme } from '@mui/material/styles'
import { PaymentSchema } from 'entities/payment/model'
import {
  OperationList,
  OperationListProps,
} from 'shared/ui/OperationList/OperationList'
import { PageProps, PaletteVariant } from 'shared/types/common'
import { generatePath, useNavigate } from 'react-router'
import { useSwipeable } from 'react-swipeable'
import { RoutePath } from 'app/config/routeConfig/routeConfig'
import { formatAmount } from 'shared/lib/formatAmount'
import { PageLayout } from 'shared/ui/PageLayout/PageLayout'
import { useApprovePaymentContext } from 'features/payment/approve'
import { PageError } from 'shared/ui/PageError/PageError'

const PaymentsSkeleton = ({
  visible,
  children,
}: {
  visible: boolean
  children: JSX.Element
}) => {
  if (!visible) return children
  return (
    <Stack spacing={1} px={2} pt={1}>
      <Box pb={1}>
        <Skeleton variant="rounded" height={32} width={124} />
      </Box>
      <Skeleton variant="rounded" height={37} />
      <Skeleton variant="rounded" height={56} />
      <Skeleton variant="rounded" height={56} />
    </Stack>
  )
}

export const PaymentsPage = ({ isOnline }: PageProps) => {
  const navigate = useNavigate()

  const {
    originalData,
    paymentsStatusFilter,
    paymentsPeriodFilter,
    paymentsStartPeriodDate,
  } = paymentModel.usePaymentsState()

  const {
    setPaymentsStatusFilter,
    setPaymentsPeriodFilter,
    setPaymentsStartPeriodDate,
  } = paymentModel.useFiltersActions()

  const filteredPayments = paymentModel.useFilteredPayments()

  const startDateQuery = useMemo(() => {
    if (
      paymentsPeriodFilter.start &&
      paymentsPeriodFilter.start.diff(
        paymentsStartPeriodDate.clone().add(1, 'month'),
        'month'
      ) < 0
    ) {
      return paymentsPeriodFilter.start.format('YYYY-MM-DD')
    }
    return paymentsStartPeriodDate.format('YYYY-MM-DD')
  }, [paymentsPeriodFilter, paymentsStartPeriodDate])

  const endDateQuery = useMemo(() => {
    if (
      paymentsPeriodFilter.end &&
      paymentsPeriodFilter.end.diff(
        paymentsStartPeriodDate.clone().add(1, 'month'),
        'month'
      ) >= 2
    ) {
      return paymentsPeriodFilter.end.format('YYYY-MM-DD')
    }
    return paymentsStartPeriodDate
      .clone()
      .add(2, 'month')
      .endOf('month')
      .format('YYYY-MM-DD')
  }, [paymentsPeriodFilter, paymentsStartPeriodDate])

  const { isLoading, isFetching, isError, refetch } =
    paymentModel.usePaymentsByPeriodQuery(
      {
        startDate: startDateQuery,
        endDate: endDateQuery,
      },
      { skip: !isOnline, refetchOnMountOrArgChange: true, refetchOnFocus: true }
    )

  const [
    approveAction,
    { isLoading: isApproving, isSuccess: approved, reset },
  ] = useApprovePaymentContext()

  const { palette } = useTheme()

  const dateHeaderRef = useRef<HTMLDivElement>(null)

  const approving = isApproving || (!isLoading && isFetching && approved)

  const operationFilterOnChange = (newValue: string) => {
    setPaymentsStatusFilter(newValue)
  }

  const getDayDotsVariants = (day: Moment) => {
    const dayInfo = originalData?.[day.format('YYYY-MM-DD')]
    return dayInfo
      ?.filter(
        ({ name }) => !paymentsStatusFilter || paymentsStatusFilter === name
      )
      .reduce(
        (result, { name, items }) =>
          items.length ? [...result, getVariantByOperationName(name)] : result,
        [] as PaletteVariant[]
      )
  }

  const dateOnChange = (targetDay: Moment) => {
    if (paymentsPeriodFilter.start && !paymentsPeriodFilter.end) {
      if (targetDay.isAfter(paymentsPeriodFilter.start, 'day')) {
        setPaymentsPeriodFilter(
          paymentModel.serializerPeriodFilter({
            ...paymentsPeriodFilter,
            end: targetDay,
          })
        )
      } else if (targetDay.isSame(paymentsPeriodFilter.start, 'day'))
        setPaymentsPeriodFilter({ start: null, end: null })
      else
        setPaymentsPeriodFilter(
          paymentModel.serializerPeriodFilter({
            start: targetDay,
            end: null,
          })
        )
    } else
      setPaymentsPeriodFilter(
        paymentModel.serializerPeriodFilter({ start: targetDay, end: null })
      )
  }

  const onMonthChange = (targetMonth: Moment) => {
    if (
      targetMonth.diff(moment().endOf('month'), 'month') > -4 &&
      targetMonth.diff(moment().startOf('month'), 'month') < 4
    ) {
      setPaymentsStartPeriodDate(
        targetMonth.clone().add(-1, 'month').format('YYYY-MM-DD')
      )
      reset()
    }
  }

  const onApprove = async ({ guid, confirmed }: PaymentSchema) => {
    reset()
    await approveAction({ guid, confirmed: confirmed ? 0 : 1 })
  }

  const onDocClick: OperationListProps<PaymentSchema>['onRowClick'] = ({
    id,
  }) => {
    navigate(
      generatePath(RoutePath.payment, {
        paymentid: id,
      })
    )
  }

  const onSwipedLeft = () => {
    if (
      paymentsStartPeriodDate.clone().diff(moment().startOf('month'), 'month') <
      1
    ) {
      setPaymentsStartPeriodDate(
        paymentsStartPeriodDate.clone().add(1, 'month').format('YYYY-MM-DD')
      )
    }
  }

  const onSwipedRight = () => {
    if (
      paymentsStartPeriodDate.clone().diff(moment().endOf('month'), 'month') >
      -3
    ) {
      setPaymentsStartPeriodDate(
        paymentsStartPeriodDate.clone().add(-1, 'month').format('YYYY-MM-DD')
      )
    }
  }

  const shouldDisableMonth = (date: Moment) => {
    if (date.diff(moment().endOf('month'), 'month') < -2) return true
    if (date.diff(moment().startOf('month'), 'month') > 2) return true
    return false
  }

  const { ref: swipeRef } = useSwipeable({ onSwipedLeft, onSwipedRight })

  return (
    <>
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={approving}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <PageLayout
        header={
          <Stack spacing={1} pb={1} borderBottom={`1px solid ${grey[300]}`}>
            <Typography variant="h4" fontWeight="bold" px={2}>
              Безнал
            </Typography>
            <ToggleOperationFilter
              options={['Разрешено', 'Не подтверждено']}
              getOptionValue={(option) => option}
              getLabel={(option) => option}
              isSelected={(option) => paymentsStatusFilter === option}
              onChange={operationFilterOnChange}
            />
          </Stack>
        }
      >
        {
          <Box
            flexDirection="column"
            display="flex"
            flexGrow={1}
            ref={swipeRef}
          >
            <Box my={2}>
              <Calendar
                value={paymentsStartPeriodDate.clone().add(1, 'month')}
                period={paymentsPeriodFilter}
                loading={isLoading}
                disabled={isError}
                onChange={dateOnChange}
                onMonthChange={onMonthChange}
                getDayDotsVariants={getDayDotsVariants}
                shouldDisableMonth={shouldDisableMonth}
              />
            </Box>
            <PaymentsSkeleton visible={isLoading}>
              <PageError visible={isError} action={refetch}>
                <>
                  {filteredPayments.length ? (
                    filteredPayments.map((doc) => (
                      <Box
                        key={doc.date}
                        px={2}
                        py={1}
                        borderTop={`1px solid ${grey[300]}`}
                      >
                        <Box
                          position="sticky"
                          top={0}
                          bgcolor="white"
                          zIndex={20}
                          pb={1}
                          ref={dateHeaderRef}
                        >
                          <Typography variant="h5" color={palette.primary.main}>
                            {moment().isSame(doc.date, 'day')
                              ? 'Сегодня'
                              : moment(doc.date).format('D MMMM')}
                          </Typography>
                        </Box>
                        {doc.items.map(({ name, items }) =>
                          items.length ? (
                            <OperationList
                              key={name}
                              header={name}
                              data={items}
                              getId={(data) => data.guid}
                              getTitle={(data) =>
                                data.provider || 'Без получателя'
                              }
                              getDescription={(data) =>
                                formatAmount(data.sum / 100, {
                                  currency: 'RUB',
                                  withZero: true,
                                })
                              }
                              getType={(data) => data?.financial_org || ''}
                              variant={getVariantByOperationName(name)}
                              top={dateHeaderRef.current?.offsetHeight}
                              onRowClick={onDocClick}
                              renderAddContent={(data) => (
                                <>
                                  <Grid
                                    size={12}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="end"
                                  >
                                    <Switch
                                      checked={!!data.confirmed}
                                      disabled={!!data.closed || !isOnline}
                                      onChange={() => onApprove(data)}
                                    />
                                  </Grid>
                                  {!!data.closed && (
                                    <Grid size={12} textAlign="end" px={1}>
                                      <Typography fontSize={12} color="info">
                                        Закрыт
                                      </Typography>
                                    </Grid>
                                  )}
                                </>
                              )}
                            />
                          ) : null
                        )}
                      </Box>
                    ))
                  ) : (
                    <Box
                      flexGrow={1}
                      flexDirection="column"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderTop={`1px solid ${grey[300]}`}
                    >
                      <Typography fontSize={16} color="textSecondary">
                        нет документов
                      </Typography>
                    </Box>
                  )}
                </>
              </PageError>
            </PaymentsSkeleton>
          </Box>
        }
      </PageLayout>
    </>
  )
}

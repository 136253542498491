import { createApi } from '@reduxjs/toolkit/query/react'
import { customBaseQuery } from 'shared/api/customFetchBaseQuery'
import { SERVER_URL } from 'shared/configs/serverConfig'
import { PaymentSchema } from './payment.types'
import { CalendarObjectSchema } from 'shared/types/common'

export const api = createApi({
  reducerPath: 'payment-api',
  tagTypes: ['PAYMENTS', 'PAYMENT'],
  baseQuery: customBaseQuery({ baseUrl: `${SERVER_URL}/payments` }),
  endpoints: (builder) => ({
    paymentsByPeriod: builder.query<
      CalendarObjectSchema<PaymentSchema>,
      { startDate: string; endDate: string }
    >({
      query: ({ startDate, endDate }) => ({
        url: `/period?startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
      }),
      providesTags: ['PAYMENTS'],
      onQueryStarted: async (arg, api) => {
        try {
          const { data } = await api.queryFulfilled
          api.dispatch({ type: 'payment-slice/setData', payload: data })
        } catch (error) {
          console.error(error)
        }
      },
    }),

    payment: builder.query<PaymentSchema, string>({
      query: (paymentId) => ({
        url: `/payment/${paymentId}`,
        method: 'GET',
      }),
      providesTags: ['PAYMENT'],
    }),

    approvePayment: builder.mutation<
      PaymentSchema,
      Pick<PaymentSchema, 'guid' | 'confirmed'> & { force?: number }
    >({
      query: ({ guid, confirmed, force }) => ({
        url: `/approve/${guid}?force=${force ?? 0}`,
        method: 'PUT',
        body: { confirmed },
      }),
      invalidatesTags: ['PAYMENTS', 'PAYMENT'],
    }),
  }),
})

export const {
  usePaymentsByPeriodQuery,
  usePaymentQuery,
  useApprovePaymentMutation,
} = api

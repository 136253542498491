import {
  Box,
  BottomNavigation,
  BottomNavigationAction,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material'
import { useNavigate, useLocation, matchPath, Outlet } from 'react-router-dom'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import ReceiptIcon from '@mui/icons-material/Receipt'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
// import PaymentsIcon from '@mui/icons-material/Payments'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { useRef, useState, useMemo, useLayoutEffect } from 'react'
import { RoutePath } from 'app/config/routeConfig/routeConfig'
import { grey } from '@mui/material/colors'

export const Navbar = () => {
  const [value, setValue] = useState(0)
  const navigate = useNavigate()
  const location = useLocation()

  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)

  const navbarItems = useMemo(
    () => [
      {
        value: 1,
        label: 'Наличка',
        icon: <PointOfSaleIcon sx={{ fontSize: 35 }} />,
        url: RoutePath.main,
        onClick: () => navigate(RoutePath.main),
      },
      {
        value: 5,
        label: 'Безнал',
        icon: <ReceiptIcon sx={{ fontSize: 35 }} />,
        url: RoutePath.payments,
        onClick: () => navigate(RoutePath.payments),
      },
      {
        value: 2,
        label: 'Счета',
        icon: <AccountBalanceWalletIcon sx={{ fontSize: 35 }} />,
        url: RoutePath.accounts,
        onClick: () => navigate(RoutePath.accounts),
      },
      // {
      //   value: 3,
      //   label: 'Расходы',
      //   icon: <PaymentsIcon sx={{ fontSize: 35 }} />,
      //   url: RoutePath.expenses,
      //   onClick: () => navigate(RoutePath.expenses),
      // },
      {
        value: 4,
        label: 'Еще',
        icon: <MoreHorizIcon sx={{ fontSize: 35 }} />,
        url: 'none',
        onClick: () => setOpen(true),
        ref: anchorRef,
      },
    ],
    [navigate]
  )

  const options = [
    { label: 'Настройки', url: RoutePath.settings },
    { label: 'Выход', url: RoutePath.logout },
  ]

  useLayoutEffect(() => {
    const targetItem = navbarItems.find((item) =>
      matchPath(location.pathname, item.url)
    )
    if (targetItem) {
      setValue(targetItem.value)
    } else {
      setValue(0)
    }
  }, [location, navbarItems])

  const dropdownOnClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  return (
    <Box
      borderTop={`1px solid ${grey[300]}`}
      position="fixed"
      bottom={0}
      width="100%"
      zIndex={999}
    >
      <BottomNavigation
        sx={{ height: 60 }}
        showLabels
        value={value}
        onChange={(event, newValue) => {
          newValue !== 4 && setValue(newValue)
        }}
      >
        {navbarItems.map(({ label, icon, value, ref, onClick }) => (
          <BottomNavigationAction
            key={label}
            label={<Box sx={{ fontSize: 14 }}>{label}</Box>}
            icon={icon}
            value={value}
            onClick={onClick}
            ref={ref}
          />
        ))}

        <Popper
          sx={{ zIndex: 1 }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={dropdownOnClose}>
                  <MenuList autoFocusItem>
                    {options.map(({ label, url }) => (
                      <MenuItem key={label} onClick={() => navigate(url)}>
                        {label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </BottomNavigation>
    </Box>
  )
}

export const NavbarWrapper = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="space-between"
    >
      <Navbar />
      <Outlet />
    </Box>
  )
}

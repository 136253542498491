import ErrorIcon from '@mui/icons-material/Error'
import { Modal, Box, Typography, Stack, Button } from '@mui/material'
import { documentModel } from 'entities/document'
import { ReactNode, useEffect, useState } from 'react'
import { Notification } from 'shared/ui/Notification/Notification'
import { ApproveDocumentContext } from '../lib/ApproveDocumentContext'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '85%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 4,
  padding: 2,
}

interface ApproveDocumenProviderProps {
  children: ReactNode
}

interface Error {
  status: number
  data: {
    error: string
    message: string[]
    data: Array<{ value: string; color?: string }>
  }
}

export const ApproveDocumenProvider = ({
  children,
}: ApproveDocumenProviderProps) => {
  const approveMethods = documentModel.useApproveDocumentMutation()

  const [approveAction, { error, originalArgs }] = approveMethods

  const [open, setOpen] = useState(false)
  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)

  const [openErrorNotify, setOpenErrorNotify] = useState(false)

  useEffect(() => {
    if (error && 'status' in error) {
      if (error.status === 412) onOpen()
      else setOpenErrorNotify(true)
    }
  }, [error, originalArgs])

  const header = (error as Error)?.data.error

  const content = (error as Error)?.data.data

  const message = (error as Error)?.data.message

  const onOk = async () => {
    onClose()
    if (originalArgs?.guid && typeof originalArgs?.confirmed === 'number')
      await approveAction({ ...originalArgs, force: 1 })
  }

  return (
    <ApproveDocumentContext.Provider value={approveMethods}>
      {children}
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h5" component="span">
            {header}
          </Typography>
          {content && (
            <Stack
              direction="row"
              spacing={0.5}
              alignItems="center"
              flexWrap="wrap"
              mt={3}
            >
              {content.map(({ value, color }, index) => (
                <Typography
                  key={index}
                  fontSize={18}
                  color={color}
                  display="inline"
                >
                  {value}
                </Typography>
              ))}
            </Stack>
          )}
          {!!Array.isArray(message) && (
            <Box sx={{ mt: 2 }}>
              {message.map((text) => (
                <Typography id="modal-modal-description" fontSize={18}>
                  {text}
                </Typography>
              ))}
            </Box>
          )}
          <Stack
            sx={{ mt: 4 }}
            spacing={2}
            direction="row"
            justifyContent="space-between"
          >
            <Box flexGrow={1}>
              <Button
                size="large"
                sx={{
                  width: '100%',
                  fontSize: 18,
                  flexGrow: 1,
                  fontWeight: 'normal',
                  textTransform: 'uppercase',
                }}
                onClick={onClose}
              >
                Отмена
              </Button>
            </Box>

            <Button
              size="large"
              variant="contained"
              color="primary"
              sx={{
                fontSize: 18,
                fontWeight: 'normal',
                textTransform: 'uppercase',
              }}
              onClick={onOk}
            >
              Согласовать
            </Button>
          </Stack>
        </Box>
      </Modal>
      <Notification
        open={openErrorNotify}
        message="Ошибка согласования"
        icon={<ErrorIcon />}
        onClose={() => setOpenErrorNotify(false)}
      />
    </ApproveDocumentContext.Provider>
  )
}

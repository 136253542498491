import { Grid2, ToggleButton, Box } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { grey } from '@mui/material/colors'
import { useTheme } from '@mui/material/styles'
import { getVariantByOperationName } from 'shared/lib/getOperationVariantByName'

type ToggleOperationFilterProps<Data> = {
  options: Array<Data>
  getOptionValue: (option: Data) => string
  getLabel: (option: Data) => string
  isSelected: (option: Data) => boolean
  onChange: (value: Data) => void
}

export function ToggleOperationFilter<Data>({
  options,
  getOptionValue,
  getLabel,
  onChange,
  isSelected,
}: ToggleOperationFilterProps<Data>) {
  const { palette } = useTheme()

  const getIconLeft = () => {
    const count = options.length
    if (!(count % 3)) return 4
    return 8
  }

  return (
    <Grid2 container rowSpacing={1} columnSpacing={{ xs: 1 }} px={1.5}>
      {options.map((option) => {
        const label = getLabel(option)
        const value = getOptionValue(option)
        const selected = isSelected(option)
        const variant = getVariantByOperationName(getLabel(option))
        return (
          <Grid2
            key={label}
            size={{
              xs:
                options.length > 3
                  ? Math.floor(24 / options.length)
                  : 12 / options.length,
            }}
          >
            <ToggleButton
              key={label}
              value={value}
              selected={selected}
              color={variant}
              sx={{
                width: '100%',
                height: 32,
                textTransform: 'none',
                borderRadius: 2,
                position: 'relative',
                color: grey['900'],
                boxShadow: `0px 0px 1px ${grey[400]}`,
              }}
              onChange={(_, value) => onChange(value)}
            >
              <FiberManualRecordIcon
                sx={{
                  fontSize: 8,
                  position: 'absolute',
                  left: getIconLeft(),
                  bottom: 12,
                  color: palette[variant].main,
                }}
              />
              <Box
                fontSize={12}
                lineHeight="12px"
                textAlign="center"
                width="100%"
              >
                {label}
              </Box>
            </ToggleButton>
          </Grid2>
        )
      })}
    </Grid2>
  )
}

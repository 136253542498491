import { Box, Skeleton, Stack, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import LoadingButton from '@mui/lab/LoadingButton'
import { grey } from '@mui/material/colors'
import { skipToken } from '@reduxjs/toolkit/query'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { RoutePath } from 'app/config/routeConfig/routeConfig'
import { documentModel } from 'entities/document'
import { DocumentSchema } from 'entities/document/model'
import { useNavigate, useParams } from 'react-router'
import { formatAmount } from 'shared/lib/formatAmount'
import { PageLayout } from 'shared/ui/PageLayout/PageLayout'
import { useApproveDocumentContext } from 'features/document/approve'
import { PageProps } from 'shared/types/common'
import { PageError } from 'shared/ui/PageError/PageError'
import moment from 'moment'

const DocumentSkeleton = ({
  visible,
  children,
}: {
  visible: boolean
  children: JSX.Element
}) => {
  if (!visible) return children
  return (
    <Stack px={3} spacing={1.8} py={1}>
      {Array.from({ length: 6 }).map((_, index) => (
        <Stack key={index} spacing={1.5}>
          <Skeleton variant="rounded" height={24} width={120} />
          <Skeleton variant="rounded" height={24} width={220} />
        </Stack>
      ))}
    </Stack>
  )
}

const generateRowsModel = (data: DocumentSchema | undefined) => {
  if (!data) return []
  return [
    {
      label: 'Дата создания',
      value: moment(data.create_date).format('DD.MM.YYYY'),
    },
    {
      label: 'Номер',
      value: data.document_number,
    },
    {
      label: 'Сумма',
      value: formatAmount(data.sum / 100, { currency: 'RUB', withZero: true }),
    },
    {
      label: 'Операция',
      value: data.operation,
    },
    {
      label: 'Получатель',
      value: data.recipient,
    },
    {
      label: 'Подразделение',
      value: data.cost_division,
    },
    {
      label: 'Статья ДДС',
      value: data.cash_article,
    },
    {
      label: 'Счет',
      value: data.account_number,
    },
    {
      label: 'Фин. организация',
      value: data.financial_org,
    },
    {
      label: 'Статья расходов/активов',
      value: data.cost_article,
    },
    {
      label: 'Аналитика расходов',
      value: data.cost_analytics,
    },
    {
      label: 'Комментарий',
      value: data.comment,
    },
  ]
}

export const DocumentPage = ({ isOnline }: PageProps) => {
  const navigate = useNavigate()

  const { documentid } = useParams()

  const {
    currentData: data,
    isLoading,
    isError,
    refetch,
  } = documentModel.useDocumentQuery(documentid ?? skipToken, {
    skip: !isOnline,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  })

  const onBack = () => navigate(RoutePath.main)

  const rowsModel = generateRowsModel(data)

  const [approveAction, { isLoading: isApproving, reset }] =
    useApproveDocumentContext()

  const onApprove = async ({ guid, confirmed }: DocumentSchema) => {
    reset()
    await approveAction({ guid, confirmed: confirmed ? 0 : 1 })
  }

  const renderStatus = () => {
    if (!data) return null
    if (data.deleted)
      return (
        <Typography color="error" fontSize={'18px'}>
          Удален
        </Typography>
      )
    if (!data.actual) {
      return (
        <Typography color="info" fontSize={'18px'}>
          ДС выданы
        </Typography>
      )
    }
    if (data.autoApproving)
      return (
        <Typography color="success" fontSize={'18px'}>
          Автосогласование
        </Typography>
      )
  }

  const allowAction =
    !!data && !data.deleted && !data.autoApproving && !!data.actual

  return (
    <>
      <PageLayout
        withNavbar={!!data}
        header={
          <Stack px={2} pb={1.5} borderBottom={`1px solid ${grey[300]}`}>
            <Stack direction="row" alignItems="center" onClick={onBack}>
              <ArrowBackIosIcon />
              <Typography variant="h4" fontWeight="bold">
                РКО
              </Typography>
            </Stack>
            <Box pl={1}>
              {isLoading ? (
                <Box my="6px">
                  <Skeleton variant="rounded" height={24} width="80%" />
                </Box>
              ) : (
                <Typography fontSize={'20px'} lineHeight="36px" color="info">
                  {data?.manager}
                </Typography>
              )}
            </Box>
          </Stack>
        }
      >
        <DocumentSkeleton visible={isLoading}>
          <PageError visible={isError} action={refetch}>
            <Grid container px={3} rowSpacing={1.5} py={1}>
              {rowsModel.map(({ label, value }) => (
                <Grid
                  key={label}
                  container
                  size={12}
                  rowSpacing={0.1}
                  color="primary"
                >
                  <Grid size={12}>
                    <Typography fontSize={20} fontWeight="light" color="info">
                      {label}
                    </Typography>
                  </Grid>
                  <Grid size={12}>
                    <Typography fontSize={20} fontWeight="medium">
                      {value || '-'}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </PageError>
        </DocumentSkeleton>
      </PageLayout>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="59px"
        borderTop={`1px solid ${grey[300]}`}
      >
        {allowAction ? (
          <LoadingButton
            size="medium"
            variant="contained"
            color={data?.confirmed ? 'error' : 'primary'}
            sx={{
              width: '285px',
              fontSize: 18,
              fontWeight: 'normal',
              textTransform: 'uppercase',
            }}
            disabled={!data?.actual || !isOnline}
            loading={isApproving}
            onClick={async () => {
              if (data) await onApprove(data)
            }}
          >
            {data.confirmed ? 'Отменить согласование' : 'Согласовать'}
          </LoadingButton>
        ) : (
          <Stack direction="row" spacing={1}>
            {data?.confirmed && !data.deleted ? (
              <Typography color="success" fontSize={'18px'}>
                Согласовано:
              </Typography>
            ) : (
              <Typography color="error" fontSize={'18px'}>
                Не согласовано:
              </Typography>
            )}
            {renderStatus()}
          </Stack>
        )}
      </Box>
    </>
  )
}

import { Box, Skeleton, Stack, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import LoadingButton from '@mui/lab/LoadingButton'
import { grey } from '@mui/material/colors'
import { skipToken } from '@reduxjs/toolkit/query'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { RoutePath } from 'app/config/routeConfig/routeConfig'
import { paymentModel } from 'entities/payment'
import { PaymentSchema } from 'entities/payment/model'
import { useNavigate, useParams } from 'react-router'
import { formatAmount } from 'shared/lib/formatAmount'
import { PageLayout } from 'shared/ui/PageLayout/PageLayout'
import { useApprovePaymentContext } from 'features/payment/approve'
import { PageProps } from 'shared/types/common'
import moment from 'moment'
import { PageError } from 'shared/ui/PageError/PageError'

const PaymentSkeleton = ({
  visible,
  children,
}: {
  visible: boolean
  children: JSX.Element
}) => {
  if (!visible) return children
  return (
    <Stack px={3} spacing={1.8} py={1}>
      {Array.from({ length: 6 }).map((_, index) => (
        <Stack key={index} spacing={1.5}>
          <Skeleton variant="rounded" height={24} width={120} />
          <Skeleton variant="rounded" height={24} width={220} />
        </Stack>
      ))}
    </Stack>
  )
}

const generateRowsModel = (data: PaymentSchema | undefined) => {
  if (!data) return []
  return [
    {
      label: 'Номер',
      value: data.document_number,
      isHeader: false,
    },
    {
      label: 'Поставщик',
      value: data.provider,
    },
    {
      label: 'Сумма',
      value: formatAmount(data.sum / 100, { currency: 'RUB', withZero: true }),
    },
    {
      label: 'Счет',
      value: data.account_number,
    },
    {
      label: 'Дата оформления',
      value: moment(data.create_date).format('DD.MM.YYYY'),
    },
    {
      label: 'Дата оплаты',
      value: moment(data.payment_date).format('DD.MM.YYYY'),
    },
    {
      label: 'Дата поступления товара',
      value: moment(data.date_of_receipt).format('DD.MM.YYYY'),
    },
    {
      label: 'Отсрочка платежа (дн.)',
      value: data.postponement_date,
    },
    {
      label: 'Склад',
      value: data.warehouse,
    },
    {
      label: 'Фин. организация',
      value: data.financial_org,
    },
    {
      label: 'Комментарий',
      value: data.comments,
    },
    {
      label: 'Cостояние заказа',
      value: data.condition,
    },
  ]
}

export const PaymentPage = ({ isOnline }: PageProps) => {
  const navigate = useNavigate()

  const { paymentid } = useParams()

  const {
    currentData: data,
    isLoading,
    isError,
    refetch,
  } = paymentModel.usePaymentQuery(paymentid ?? skipToken, {
    skip: !isOnline,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  })

  const onBack = () => navigate(RoutePath.payments)

  const rowsModel = generateRowsModel(data)

  const [approveAction, { isLoading: isApproving, reset }] =
    useApprovePaymentContext()

  const onApprove = async ({ guid, confirmed }: PaymentSchema) => {
    reset()
    await approveAction({ guid, confirmed: confirmed ? 0 : 1 })
  }

  const renderStatus = () => {
    if (!data) return null
    if (data.deleted)
      return (
        <Typography color="error" fontSize={'18px'}>
          Удален
        </Typography>
      )
    if (!data.actual) {
      return (
        <Typography color="info" fontSize={'18px'}>
          Не проведен
        </Typography>
      )
    }
    if (data.closed) {
      return (
        <Typography color="info" fontSize={'18px'}>
          Закрыт
        </Typography>
      )
    }
  }

  const allowAction = !!data && !data.deleted && !data.closed

  return (
    <>
      <PageLayout
        withNavbar={!!data}
        header={
          <Stack px={2} pb={1.5} borderBottom={`1px solid ${grey[300]}`}>
            <Stack direction="row" alignItems="center" onClick={onBack}>
              <ArrowBackIosIcon />
              <Typography variant="h4" fontWeight="bold">
                Заказ поставщику
              </Typography>
            </Stack>
            <Box pl={1}>
              {isLoading ? (
                <Box my="6px">
                  <Skeleton variant="rounded" height={24} width="80%" />
                </Box>
              ) : (
                <Typography fontSize={'20px'} lineHeight="36px" color="info">
                  {data?.manager}
                </Typography>
              )}
            </Box>
          </Stack>
        }
      >
        <PaymentSkeleton visible={isLoading}>
          <PageError visible={isError} action={refetch}>
            <Grid container px={3} rowSpacing={1.5} py={1}>
              {rowsModel.map(({ label, value, isHeader }) => (
                <Grid
                  key={label}
                  container
                  size={12}
                  rowSpacing={0.1}
                  color="primary"
                >
                  <Grid size={isHeader ? undefined : 12}>
                    <Typography
                      fontSize={isHeader ? 26 : 20}
                      fontWeight={isHeader ? 'normal' : 'light'}
                      color={isHeader ? 'primary' : 'info'}
                    >
                      {label}
                    </Typography>
                  </Grid>
                  <Grid size={isHeader ? undefined : 12}>
                    <Typography
                      fontSize={isHeader ? 26 : 20}
                      fontWeight={isHeader ? 'normal' : 'medium'}
                      color={isHeader ? 'primary' : undefined}
                    >
                      {value ? value : typeof value === 'string' ? '-' : value}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </PageError>
        </PaymentSkeleton>
      </PageLayout>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="59px"
        borderTop={`1px solid ${grey[300]}`}
      >
        {allowAction ? (
          <LoadingButton
            size="medium"
            variant="contained"
            color={data?.confirmed ? 'error' : 'primary'}
            sx={{
              width: '285px',
              fontSize: 18,
              fontWeight: 'normal',
              textTransform: 'uppercase',
            }}
            disabled={!data?.actual || !isOnline}
            loading={isApproving}
            onClick={async () => {
              if (data) await onApprove(data)
            }}
          >
            {data.confirmed ? 'Отменить согласование' : 'Согласовать'}
          </LoadingButton>
        ) : (
          <Stack direction="row" spacing={1}>
            {data?.confirmed && !data.deleted ? (
              <Typography color="success" fontSize={'18px'}>
                Согласовано:
              </Typography>
            ) : (
              <Typography color="error" fontSize={'18px'}>
                Не согласовано:
              </Typography>
            )}
            {renderStatus()}
          </Stack>
        )}
      </Box>
    </>
  )
}

import { configureStore } from '@reduxjs/toolkit'
import { documentModel } from 'entities/document'
import { userModel } from 'entities/user'
import { settingsModel } from 'entities/settings'
import { accountModel } from 'entities/account'
import { paymentModel } from 'entities/payment'

export const store = configureStore({
  reducer: {
    [userModel.api.reducerPath]: userModel.api.reducer,
    [documentModel.api.reducerPath]: documentModel.api.reducer,
    [documentModel.slice.reducerPath]: documentModel.slice.reducer,
    [paymentModel.api.reducerPath]: paymentModel.api.reducer,
    [paymentModel.slice.reducerPath]: paymentModel.slice.reducer,
    [accountModel.api.reducerPath]: accountModel.api.reducer,
    [settingsModel.api.reducerPath]: settingsModel.api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      userModel.api.middleware,
      documentModel.api.middleware,
      paymentModel.api.middleware,
      accountModel.api.middleware,
      settingsModel.api.middleware,
    ]),
})

export type RootState = ReturnType<typeof store.getState>
export type RootDispatch = typeof store.dispatch

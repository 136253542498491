const Key = {
  userPhone: 'user_phone',
  userToken: 'user_token',
  docsStatusFilter: 'docs_status_filter',
  docsPeriodFilter: 'docs_period_filter',
  docsStartPeriodDate: 'docs_month',
  initLocationHref: 'init_location_href',
  paymentsStartPeriodDate: 'payments_start_period_date',
  paymentsPeriodFilter: 'payments_period_filter',
  paymentsStatusFilter: 'payments_status_filter',
  accountsTabIndex: 'accounts_tab_index',
} as const

type LocalStorageReturnValue = string | object | null

export const localStorageService = {
  set(key: keyof typeof Key, value: string): void {
    localStorage.setItem(Key[key], value)
  },
  get(key: keyof typeof Key): LocalStorageReturnValue {
    const localValue = localStorage.getItem(Key[key])
    if (localValue) {
      try {
        const result = JSON.parse(localValue)
        if (typeof result === 'number') return result.toString()
        return result
      } catch (error) {
        return localValue
      }
    } else return null
  },
  remove(key: keyof typeof Key): void {
    localStorage.removeItem(Key[key])
  },
}

import { Box, Link, Typography, useTheme } from '@mui/material'
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred'

interface PageErrorProps {
  children: JSX.Element
  visible: boolean
  message?: string
  action?: () => void
}

export const PageError = ({
  message,
  children,
  visible,
  action,
}: PageErrorProps) => {
  const { palette } = useTheme()

  if (!visible) return children

  return (
    <Box height="100%" px={2} display="flex" alignItems="center">
      <Box
        width="100%"
        px={1}
        py={1}
        bgcolor={palette.error.light}
        borderRadius={3}
        textAlign="center"
      >
        <ReportGmailerrorredIcon color="error" />
        <Typography fontSize={14} fontWeight="bold" color={palette.error.main}>
          {message || 'Ошибка загрузки данных'}
        </Typography>
        {action && (
          <Box mt={0.5}>
            <Link
              component="button"
              variant="button"
              color="textPrimary"
              onClick={action}
            >
              Повторить
            </Link>
          </Box>
        )}
      </Box>
    </Box>
  )
}

import {
  bindActionCreators,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import moment, { Moment } from 'moment'
import { useRootDispatch, useTypedSelector } from 'shared/hooks/redux'
import { localStorageService } from 'shared/lib/localStorageService'
import { typedObjectEntries } from 'shared/lib/typedObjectEntries'
import { CalendarObjectSchema, OperationSchema } from 'shared/types/common'
import { PaymentSchema } from './payment.types'

const defaultPaymentsPeriodFilter: {
  start: null | string
  end: null | string
} = {
  start: null,
  end: null,
}

type SerializerPaymentsPeriodFilter = Record<
  keyof typeof defaultPaymentsPeriodFilter,
  string | null
>
type DeserializerPaymentsPeriodFilter = Record<
  keyof typeof defaultPaymentsPeriodFilter,
  Moment | null
>

interface ListData {
  date: string
  items: OperationSchema<PaymentSchema>[]
}

interface PaymentsFiltersState {
  originalData: CalendarObjectSchema<PaymentSchema> | undefined
  paymentsStartPeriodDate: string
  paymentsStatusFilter: string | null
  paymentsPeriodFilter: typeof defaultPaymentsPeriodFilter
}

export const serializerPeriodFilter = (
  value: DeserializerPaymentsPeriodFilter
) =>
  typedObjectEntries(value).reduce(
    (result, [key, value]) => ({
      ...result,
      [key]: value && value.format('YYYY-MM-DD'),
    }),
    {} as SerializerPaymentsPeriodFilter
  )

const deserializerPeriodFilter = (value: SerializerPaymentsPeriodFilter) =>
  typedObjectEntries(value).reduce(
    (result, [key, value]) => ({
      ...result,
      [key]: value && moment(value),
    }),
    {} as DeserializerPaymentsPeriodFilter
  )

const initPaymentsStartPeriodDate = () => {
  const storage = localStorageService.get('paymentsStartPeriodDate') as
    | string
    | null
  if (!storage)
    return moment().startOf('month').add(-1, 'month').format('YYYY-MM-DD')
  return storage
}

const initPaymentsPeriodFilter = () => {
  const storage = localStorageService.get(
    'paymentsPeriodFilter'
  ) as SerializerPaymentsPeriodFilter | null
  if (!storage) return defaultPaymentsPeriodFilter
  return storage
}

export const initialPaymentsFiltersState: PaymentsFiltersState = {
  originalData: undefined,
  paymentsStartPeriodDate: initPaymentsStartPeriodDate(),
  paymentsStatusFilter: localStorageService.get('paymentsStatusFilter') as
    | string
    | null,
  paymentsPeriodFilter: initPaymentsPeriodFilter(),
}

export const slice = createSlice({
  name: 'payment-slice',
  initialState: initialPaymentsFiltersState,
  reducers: {
    setData(
      state,
      { payload }: PayloadAction<CalendarObjectSchema<PaymentSchema>>
    ) {
      if (state.originalData) {
        state.originalData = { ...state.originalData, ...payload }
      } else {
        state.originalData = payload
      }
    },
    setPaymentsStartPeriodDate(
      state,
      {
        payload,
      }: PayloadAction<
        NonNullable<PaymentsFiltersState['paymentsStartPeriodDate']>
      >
    ) {
      state.paymentsStartPeriodDate = payload
      localStorageService.set('paymentsStartPeriodDate', payload)
    },
    setPaymentsStatusFilter(
      state,
      {
        payload,
      }: PayloadAction<NonNullable<
        PaymentsFiltersState['paymentsStatusFilter']
      > | null>
    ) {
      if (!payload || state.paymentsStatusFilter === payload) {
        state.paymentsStatusFilter = null
        localStorageService.remove('paymentsStatusFilter')
      } else {
        state.paymentsStatusFilter = payload
        localStorageService.set('paymentsStatusFilter', payload)
      }
    },
    setPaymentsPeriodFilter(
      state,
      { payload }: PayloadAction<PaymentsFiltersState['paymentsPeriodFilter']>
    ) {
      state.paymentsPeriodFilter = payload
      localStorageService.set('paymentsPeriodFilter', JSON.stringify(payload))
    },
  },
  selectors: {
    selectState: createSelector([(state) => state], (state) => ({
      ...state,
      paymentsStartPeriodDate: moment(state.paymentsStartPeriodDate),
      paymentsPeriodFilter: deserializerPeriodFilter(
        state.paymentsPeriodFilter
      ),
    })),
    selectFilteredPayments: createSelector(
      [(state) => state],
      (state: PaymentsFiltersState) => {
        const { originalData, paymentsPeriodFilter, paymentsStatusFilter } =
          state
        const { start, end } = paymentsPeriodFilter

        const startMoment = start ? moment(start) : undefined
        const endMoment = end ? moment(end) : undefined

        if (originalData) {
          const paymentsByPeriod = Object.entries(originalData)
            .reduce((result, [date, dateData]) => {
              if (
                ((!startMoment &&
                  !endMoment &&
                  moment().isSameOrBefore(date, 'day')) ||
                  (!endMoment && startMoment?.isSame(date, 'day')) ||
                  (startMoment?.isSameOrBefore(date, 'day') &&
                    endMoment?.isSameOrAfter(date, 'day'))) &&
                dateData.some(({ items }) => items.length)
              ) {
                return [...result, { date, items: dateData }]
              }
              return result
            }, [] as ListData[])
            .sort(
              (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
            )

          if (paymentsStatusFilter) {
            const filteredPayments = paymentsByPeriod.reduce((result, data) => {
              const newItems = data.items.filter(
                ({ name, items }) =>
                  paymentsStatusFilter === name && items.length
              )
              if (newItems.length)
                return [
                  ...result,
                  {
                    ...data,
                    items: newItems,
                  },
                ]
              return result
            }, [] as ListData[])

            return filteredPayments
          } else return paymentsByPeriod
        }
        return []
      }
    ),
  },
})

const { selectState, selectFilteredPayments } = slice.selectors

export const usePaymentsState = () =>
  useTypedSelector<
    Omit<
      PaymentsFiltersState,
      'paymentsPeriodFilter' | 'paymentsStartPeriodDate'
    > & {
      paymentsPeriodFilter: DeserializerPaymentsPeriodFilter
      paymentsStartPeriodDate: Moment
    }
  >(selectState)

export const useFilteredPayments = () =>
  useTypedSelector<ListData[]>(selectFilteredPayments)

export const useFiltersActions = () => {
  const dispatch = useRootDispatch()
  return bindActionCreators(slice.actions, dispatch)
}
